<template>
	<div>
		<el-row style="margin-bottom: 20px">
			<el-col :span="4">
				<el-select v-model="params.user_type" placeholder="请选择巡察组">
					<el-option v-for="item in provinceid" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="4">
				<el-button type="primary" @click="init()">查询</el-button>
				<el-button type="" @click="add_data(1)">新增巡查组</el-button>
			</el-col>
		</el-row>
		<el-table :data="tableData" border style="width: 100%" center>
			<el-table-column prop="id" label="编号"> </el-table-column>
			<el-table-column prop="company_name" label="单位名称"> </el-table-column>
			<el-table-column prop="patrol_role_name" label="单位类型"> </el-table-column>
			<el-table-column prop="" label="地区">
				<template slot-scope="scope">
					<p>{{scope.row.province_name}} {{scope.row.city_name}} {{scope.row.area_name}}
						{{scope.row.street_name}}
					</p>
				</template>
			</el-table-column>
			<el-table-column prop="address" label="详细地址"> </el-table-column>
			<el-table-column label="编辑">
				<template slot-scope="scope">
					<el-button @click="add_data(2,scope.row)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="display: flex;justify-content: center;align-items: center;margin-top: 15px">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="params.page" :page-sizes="[10, 20, 30]" :page-size="params.limit"
				layout="total, sizes, prev, pager, next, jumper" :total="params.total">
			</el-pagination>
		</div>
		<el-dialog :title="title_name" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
			<el-form :model="form" class="demo-ruleForm" label-width="100px">
				<el-form-item label="单位类型">
					<el-select v-model="form.user_type" placeholder="请选择单位类型">
						<el-option v-for="item in provinceid" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="省份" label-width="100px">
					<div id="" style="display: flex;">
						<el-select v-model="form.province_id" placeholder="请选择省份">
							<el-option v-for="item in Form.provinceid" :key="item.id" :label="item.name"
								:value="item.id" @click.native="clickprovinceid">
							</el-option>
						</el-select>
						<el-select v-model="form.city_id" placeholder="请选择城市">
							<el-option v-for="item in Form.cityid.children" :key="item.id" :label="item.name"
								:value="item.id" @click.native="clickcityid">
							</el-option>
						</el-select>

						<el-select v-model="form.area_id" placeholder="请选择区县">
							<el-option v-for="item in Form.areaid.children" :key="item.id" :label="item.name"
								:value="item.id" @click.native="clickareaid">
							</el-option>
						</el-select>


						<el-select v-model="form.street_id" placeholder="请选择街道"
						  v-if="form.user_type==1||form.user_type==2"
						>
							<el-option v-for="item in Form.streetid.children" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>

					</div>
				</el-form-item>
				<el-form-item label="市场" v-if="form.user_type===1" label-width="100px">
					<el-select v-model="form.market_id" placeholder="请选择市场">
						<el-option v-for="item in marketidlist" :key="item.id" :label="item.comp_name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="单位名称" label-width="100px">
					<el-input v-model="form.company_name" clearable style="width: 47%"></el-input>
				</el-form-item>
				<el-form-item label="单位详细地址" label-width="100px">
					<el-input v-model="form.address" clearable style="width: 47%"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible=false">取 消</el-button>
				<el-button type="primary" @click="seaveCompanys">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	// import AeraInfo from '@/assets/pca-code.json'
	// import {} from '@/api/manage'
	import {
		getRoles,
		patrolCompanys,
		post_patrolCompanys,
		post_patrolCompanysid
	} from '@/api/manage'
	import {
		provinceCityArea,
		getcity,
		getmarket
	} from '@/api/layout'

	export default {
		name: 'list',
		data() {
			return {
				options: [],
				selectedOptions: [],
				provinceid: [],
				params: {
					province_id: '',
					page: 1,
					limit: 10,
					total: 0
				},
				Form: {
					sellerid: '', // 所属商户
					provinceid: '', // 省份
					cityid: '', // 城市
					areaid: '', // 区县
					streetid: '', // 街道
					suppliertypeid: '' // 供应商类型
				},
				tableData: [],
				form: {
					user_type: '',
					city_id: '',
					area_id: '',
					province_id: '',
					company_name: "",
					address: '',
					market_id: "",
					street_id: ""
				},
				provinceids: [],
				children: [],
				marketidlist: [],
				dialogVisible: false,
				isactive: false,
				childrenlist: [],
				title_name: "",
				item_id: ""
			}
		},
		mounted() {
			this.Get_Roles()
			this.init()
			this.getcity()
			this.getMarketId()
		},
		methods: {
			//查询列表数据
			init() {
				patrolCompanys(this.params).then(res => {
					this.tableData = res.data.data
					this.params.total = res.data.total
				})
			},
			handleClose() {
				this.dialogVisible = false

			},
			Close() {
				this.form.user_type = '',
					this.form.city_id = '',
					this.form.area_id = '',
					this.form.province_id = '',
					this.form.company_name = '',
					this.form.address = '',
					this.form.market_id = '',
					this.form.street_id = ''
			},
		async	add_data(num, item) {

				this.num_type = num
				this.dialogVisible = true
				if (num === 1) {
					this.title_name = "新增巡查单位"
					this.Close()
				} else {
					this.item_id = item.id
					this.title_name = "编辑巡查单位"
					// 	this.form = "111"

					console.log(item);
					this.form.user_type = item.user_type


						this.form.province_id = item.province_id
						this.form.company_name = item.company_name
						this.form.address = item.address
						this.form.market_id = item.market_id

						await this.clickprovinceid()
					this.form.city_id = item.city_id
						await this.clickcityid()
					this.form.area_id = item.area_id
						await this.clickareaid()
					this.form.street_id = item.street_id
				}

			},

			//获取市场
			getMarketId() {
				getmarket().then(res => {
					this.marketidlist = res.data
				})
			},
			//获取单位类型
			Get_Roles() {
				getRoles().then(res => {
					this.provinceid = res.data
				})
			},

			getcity() {
				provinceCityArea().then(res => {
					this.Form.provinceid = res.data
				})
			},
			// 城市
			clickprovinceid() {
				this.form.city_id = ''
				this.form.area_id = ''
				this.form.street_id = ''
				getcity(this.form.province_id).then(res => {
					this.Form.cityid = res.data
				})
			},
			// 区县
			clickcityid() {
				this.form.area_id = ''
				this.form.street_id = ''
				getcity(this.form.city_id).then(res => {
					this.Form.areaid = res.data
				})
			},
			// 街道
			clickareaid() {
				this.form.street_id = ''
				getcity(this.form.area_id).then(res => {
					this.Form.streetid = res.data
				})
			},
			get_street(ids) {
				// this.isactive = true
				// this.form.area_id=ids
				getcity(this.form.area_id).then(res => {
					this.childrenlist = res.data.children
					console.log(this.childrenlist)
				})
			},
			getcitychildren(s) {
				console.log(s);
			},
			//新增巡查单位
			seaveCompanys() {
				if (this.num_type === 1) {
					post_patrolCompanys(this.form).then(res => {
						if (res.code === 200) {
							this.dialogVisible = false
							this.$message.success(res.data)
							this.init()
							this.Close()
						}
					})
				} else {
					post_patrolCompanysid(this.form, this.item_id).then(res => {
						if (res.code === 200) {
							this.dialogVisible = false
							this.$message.success(res.data)
							this.init()
						}
					})
				}

			},
			//分页功能
			handleSizeChange(val) {
				this.params.page = 1
				this.params.limit = val
				this.init()
			},
			handleCurrentChange(val) {
				this.params.page = val
				this.init()
			}
		},

	}
</script>

<style scoped></style>
